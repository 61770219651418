import { DatePicker, Divider, Flex, Form, Input, Rate, Segmented, Switch } from "antd"

import SlotStatusLabel from "../slots/SlotStatusLabel"
import { TaskInput } from "../tasks/TaskInput"
import { useEffect, useMemo, useState } from "react"
import TaskDurationUnitLabel from "../tasks/TaskDurationLabel"
import TaskMessagesView from "../tasks/TaskMessagesView"
import { getPrestation } from "../../services/api/prestations"
import { listResourcePrestationsForResource } from "../../services/api/resources-prestations"
import { indexBy } from "../../lib/utils"


export default function PlanningResourceSlotForm({ form, resource, slot, slots, tasks, projects }) {

  // const [prestationInfo, setPrestationInfo] = useState({})
  const [prestations, setPrestations] = useState([])

  const type = Form.useWatch("type", { form })
  const task_id = Form.useWatch("task_id", { form })
  const all_tasks = Form.useWatch("all_tasks", { form })
  const task = useMemo(() => tasks.find(t => t.id === task_id), [task_id, tasks])
  const project = useMemo(() => projects?.find(p => p.id === task?.project_id), [task, projects])

  const slotsForTask = useMemo(() => slots.filter(s => s.task_id === task_id && s.status !== "cancelled"), [slots, task_id])

  const durationPlanned = useMemo(() => {
    return slotsForTask.reduce((acc, s) => {
      return acc + s.duration
    }, 0)
  }, [slotsForTask])

  const durationInHours = useMemo(() => {
    if (!task) {
      return 0
    }
    return task?.unit === 'J' ? Math.ceil(task.duration * 7) : task.duration
  }, [task])

  const remainingDuration = Math.max(0, durationInHours - durationPlanned)

  const remainingDurationInUnit = task?.unit === 'J' ? Math.round(10 * remainingDuration / 7) / 10 : remainingDuration

  useEffect(() => {
    if (!resource?.id) {
      return
    }
    (async () => {
      // const basePresta = await getPrestation(task.prestation_id)

      const prestas = await listResourcePrestationsForResource(resource.id)
      // const presta = prestas.find(p => p.prestation_id === task.prestation_id)
      setPrestations(prestas)

      // setPrestationInfo({
      //   performance: presta?.performance || 100,
      //   price: presta?.price || basePresta?.price || null,
      //   purchase_price: presta?.purchase_price || basePresta?.purchase_price || null,
      // })

    })()
  }, [resource?.id])

  const filteredTasks = useMemo(() => {
    if (all_tasks) {
      return tasks
    }
    const idx = indexBy(prestations, "prestation_id")
    // console.log(idx)
    return tasks.filter(t => idx[t.prestation_id])
  }, [tasks, prestations, all_tasks])

  useEffect(() => {
    if (!slot) {
      return
    }
    if (!slot.duration) {
      let hours = remainingDuration
      form.setFieldValue('duration', Math.min(7, hours || 7))
    }
  }, [form, remainingDuration, slot])
  const fullDay = type === "task"
  const unavailablity = type === "unavailablity" || slot?.status === "unavailable"
  return <Flex vertical>
    <Form form={form}>

    </Form>
    <Flex vertical>
      {false && task ? <>
        <Form.Item>
          <Flex justify="space-between" align="center">
            <Rate disabled value={Math.ceil(task?.expected_level * 3 / 5)} style={{ fontSize: '0.8em' }} count={3} />
            {/* <SlotStatusLabel status={slot?.status} /> */}
          </Flex>
          <div className="form-text" style={{ fontWeight: 'bold' }}>
            {project?.name}
          </div>
          {task?.description && <div className="form-text" style={{ maxHeight: 200, overflow: 'auto' }}>
            {task.description.split('\n').map((line, i) => <span key={i}>{line}<br /></span>)}
          </div>}
          <div>
            {task?.duration && <div className="form-text">
              Durée estimée: <b>{task.duration} <TaskDurationUnitLabel value={task.unit} plural={task.duration > 1} /></b>
            </div>}
            {task?.duration && <div className="form-text">
              Durée restante à planifier: <b>{remainingDurationInUnit} <TaskDurationUnitLabel value={task.unit} plural={remainingDurationInUnit > 1} /></b>
            </div>}
          </div>
          {/* <div>
          <div className="form-text">
            Créneaux déja planifiés pour cette tache:
          </div>
        </div> */}
        </Form.Item>
        <Divider />
      </> : null}



      <Form
        form={form}
        labelCol={{ span: 6 }}>



        {slot?.status !== "unavailable" && <Form.Item label="Type" name="type">
          <Segmented options={[
            { value: 'task', label: 'Tâche' },
            { value: 'time', label: 'Planification' },
            ...(slot.task_id ? [] : [{ value: 'unavailablity', label: 'Indisponibilité' }]),
          ]} />
        </Form.Item>}
        {!fullDay && <Form.Item label="Date" name="dates" rules={[{ required: true, message: "Veuillez spécifier un intervale de date" }]}>
          <DatePicker.RangePicker
            format={"DD/MM/YYYY HH:mm"} showTime={{ format: 'HH:mm', minuteStep: 15 }}
            variant="borderless" />
        </Form.Item>}
        {fullDay && <>
          <Form.Item label="Date" name={["dates", 0]} rules={[{ required: true, message: "Veuillez spécifier une date" }]}>
            <DatePicker format={"DD/MM/YYYY"} variant="borderless" />
          </Form.Item>
        </>}
        {!unavailablity && <Form.Item label={"Tâche"} >
          <Flex vertical justify="end">
            <Form.Item noStyle name="task_id" rules={[{ required: true, message: "Veuillez spécifier une tâche affectée" }]}>
              <TaskInput tasks={filteredTasks} />
            </Form.Item>
            <div>
              <Form.Item name="all_tasks" label="Toutes les tâches">
                <Switch size="small" />
              </Form.Item>
            </div>
          </Flex>
        </Form.Item>}
        {fullDay && <>
          <Form.Item label="Durée estimée" name="duration" rules={[{ required: true, message: "Veuillez spécifier une durée" }]}>
            <Input type="number" suffix="Heures" style={{ maxWidth: 130 }} />
          </Form.Item>
        </>
        }


      </Form>
      {task ?
        <Flex>
          <div style={{ minWidth: 300, borderRight: "1px solid #eee", paddingRight: 15, marginRight: 15 }}>
            <Flex justify="space-between" align="center">
              <Rate disabled value={Math.ceil(task?.expected_level * 3 / 5)} style={{ fontSize: '0.8em' }} count={3} />
              <SlotStatusLabel status={slot?.status} />
            </Flex>
            <div className="form-text" style={{ fontWeight: 'bold' }}>
              {project?.name}
            </div>
            {task?.description && <div className="form-text" style={{ maxHeight: 200, overflow: 'auto' }}>
              {task.description.split('\n').map((line, i) => <span key={i}>{line}<br /></span>)}
            </div>}
            <div>
              {task?.duration && <div className="form-text">
                Durée estimée: <b>{task.duration} <TaskDurationUnitLabel value={task.unit} plural={task.duration > 1} /></b>
              </div>}
              {task?.duration && <div className="form-text">
                Durée restante à planifier: <b>{remainingDurationInUnit} <TaskDurationUnitLabel value={task.unit} plural={remainingDurationInUnit > 1} /></b>
              </div>}
            </div>
          </div>
          <TaskMessagesView taskId={task?.id} />
        </Flex> : null}
    </Flex>
  </Flex>
}