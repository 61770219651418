import { Button, Divider, Flex, Row, Space } from "antd";
import SlotStatusLabel from "../slots/SlotStatusLabel";
import { PlanningErrorItem } from "./PlanningErrorItem";

import Style from "./SlotPopover.module.scss";
import ResourceIcon from "../resources/ResourceIcon";
import { formatDate, formatDateTime, formatNumber, formatProjectCode } from "../../lib/format";
import { CheckCircleOutlined, CheckOutlined, StopOutlined } from "@ant-design/icons";
import SlotCancelReasonLabel from "../slots/SlotCancelReasonLabel";
import ProjectCode from "../projects/ProjectCode";
import { durationInDaysFromHours } from "../../hooks/slots";
import { useAuth } from "../../hooks/auth";



export default function SlotPopover({
  schedulerData, eventItem, title, start, end, statusColor,
  slot, task, prestation, user, project,
  errors, users, tasks,
  onCancel, onConfirm, onDone
}) {

  const { can } = useAuth();

  if (eventItem.milestone) {
    return <div style={{ maxWidth: 300 }}>
      <h6 className={Style.title}>
        <span >{title}</span>
      </h6>
      <Space direction="vertical">
        <div className="popover-content">
          {formatDate(start.toDate())}
        </div>
      </Space>
    </div>
  }

  const durationInDays = durationInDaysFromHours(slot.duration)// Math.ceil(10 * slot.duration / 7) / 10
  const showTime = slot.type === 'time';


  return <div style={{ maxWidth: 300 }}>
    <h4 className={Style.title}>
      <ResourceIcon type={user?.type} size={1} />
      <span style={{ flex: 1, margin: '0 10px' }}>{title}</span>
    </h4>
    <h5 className={Style.prestation}>
      <SlotStatusLabel status={slot?.status} />
      {prestation?.name}
    </h5>
    <ProjectCode value={project?.code} />

    <p style={{ fontStyle: 'italic', overflow: 'auto', maxHeight: 250 }}>
      {task?.description.split(/\n/g).map((line, i) => <span key={i}>{line}<br /></span>)}
    </p>
    {slot.status === 'canceled' && slot.cancel_reason &&
      <Space style={{ color: 'red', marginBottom: 5 }} align="middle">
        Cause de l'annulation :<span style={{ fontWeight: 500 }}>
          <SlotCancelReasonLabel value={slot.cancel_reason} />
        </span>
      </Space>}
    <Flex vertical gap="small">
      {errors?.map((error, i) => <PlanningErrorItem key={error.id} error={error} users={users} tasks={tasks} />)}
      <div className="popover-content">
        {formatDate(start.toDate())} {showTime && <b>{start.format('HH:mm')}</b>} - {formatDate(end.toDate())} {showTime && <b>{end.format('HH:mm')}</b>}
        <p>
          Durée : <b>{slot.duration}h</b> &mdash; <b>{durationInDays} jour{durationInDays > 1 ? 's' : ''}</b>
          <br />
          {eventItem?.metrics.price_total > 0
            ? <>
              Coût : <b>{formatNumber(eventItem?.metrics.price_total)}€</b> à <b>{formatNumber(eventItem?.metrics.price)}€/jour</b>
            </> : null}
        </p>
      </div>

    </Flex>
    {!slot.ref ?
      <Flex gap="small" align="center" justify="space-between">
        {slot.status === 'confirmed' &&
          <Button size="small" danger icon={<StopOutlined />} onClick={() => onCancel()}>Annuler</Button>}
        {/* {slot.status !== 'done' && slot.status !== 'canceled' && slot.status !== 'unavailable' &&
          <Button size="small" danger icon={<StopOutlined />} onClick={() => onCancel()}>Supprimer</Button>} */}
        <div style={{ flex: 1 }} />
        {slot.status === 'planned' && can('slot.confirm') &&
          <Button size="small" type="primary"
            icon={<CheckOutlined />} style={{ backgroundColor: "#235789" }}
            onClick={() => onConfirm()}>Confirmer</Button>}
        {slot.status === 'confirmed' &&
          <Button size="small" type="primary"
            icon={<CheckCircleOutlined />}
            style={{ backgroundColor: "#52C41A" }}
            onClick={() => onDone()}>Terminer</Button>}
      </Flex>
      : null}
  </div>
}