import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  createResource, getResource, listResources, removeResource, updateResource,
} from '../services/api/resources';
import { loaderWrap } from '../services/loader';
import { notifyError } from '../services/notification';
import { useInvalidationService } from './invalidation';
import { cache } from '../services/cache';
import {
  isRequired, validate, validateAll,
} from '../lib/validation';
import { useForm } from './form';
import { listResourcePrestationsForPrestation } from '../services/api/resources-prestations';
import { indexBy } from '../lib/utils';


const INVALIDATION_KEY = Symbol('resources');

// const { invalidateCache, withCache } = cache();

export const resourceTypes = [
  {
    label: 'Interne',
    value: 'internal',
  },
  {
    label: 'Externe',
    value: 'external',
  },
  {
    label: 'Client',
    value: 'customer',
  },
  {
    label: 'Autre',
    value: 'other',
  },
];

export const resourceRoles = [
  { value: 'admin', label: 'Admin' },
  { value: 'shipping', label: 'Expédition' },
  { value: 'sales', label: 'Commercial' },
  { value: 'technician', label: 'Technicien' },
  { value: 'user', label: 'Utilisateur' },
];



function getDefaults() {
  return {
    code: "",
    name: "",
    type: "internal",
    description: "",
    capacity: 0,
    enabled: true,
  };
}

async function getItem(id) {
  if (id === '*' || !id) {
    return getDefaults();
  }
  try {
    return {
      ...getDefaults(),
      ...(await loaderWrap(getResource(id))),
    };
  } catch (e) {
    notifyError(e);
  }
  return {};
}

export function useResources({ prestationId, agencyId } = {}) {
  const [resources, setResources] = useState([]);
  const [counter, invalidate] = useInvalidationService(INVALIDATION_KEY);
  const [complete, setComplete] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setComplete(false);
        // const terms = await withCache(async () => loaderWrap(listResources()));
        let resources = await loaderWrap(listResources());
        if (prestationId) {
          const respres = await loaderWrap(listResourcePrestationsForPrestation(prestationId));
          const idx = indexBy(respres, 'resource_id');
          resources = resources.filter(({ id }) => !!idx[id]);
        }
        if (agencyId) {
          resources = resources.filter(({ agency_id }) => agency_id === agencyId);
        }
        setResources(resources);
        setComplete(true);
      } catch (e) {
        notifyError(e);
      }
    })();
  }, [counter, prestationId, agencyId]);

  const refresh = useCallback(() => {
    invalidate();
  }, [invalidate]);

  return [resources, refresh, complete];
}

export function useResource(id) {
  const [resource, setResource] = useState();

  const [counter, invalidate] = useInvalidationService(INVALIDATION_KEY);

  useEffect(() => {
    (async () => {
      setResource(await getItem(id));
    })();
  }, [id, counter]);

  const saveResource = useCallback(async (item) => {
    if (!item) {
      throw new Error('No resource');
    }
    let out;
    if (item.id) {
      out = await loaderWrap(updateResource(item));
    } else {
      out = await loaderWrap(createResource(item));
    }
    setResource(out);
    invalidate(id);
    return out;
  }, [id, invalidate]);

  const deleteResource = useCallback(async () => {
    await loaderWrap(removeResource(id));
    invalidate(id);
  }, [id, invalidate]);

  return {
    resource,
    reloadResource: () => invalidate(id),
    isNewResource: !resource?.id,
    setResource,
    saveResource,
    deleteResource,
  };
}


export function resourceValidator(values, name = undefined) {
  const rules = {
    name: [isRequired],
    code: [],
    type: [isRequired],
    capacity: [],
    description: [],
  };
  if (name) {
    const err = validate(values[name], ...rules[name] || []);
    return { [name]: err || null };
  }
  return validateAll(values, rules);
}

// export function useResourceForm(id) {
//   const { resource, saveResource, deleteResource } = useResource(id);

//   const {
//     register,
//     handleSubmit,
//     errors, isValid,
//     setValues,

//     values,
//   } = useForm({
//     values: {
//       ...getDefaults(),
//     },
//     validator: resourceValidator,
//     // reValidateMode: "onChange"
//   });

//   useEffect(() => {
//     if (resource) setValues(resource);
//   }, [resource]);


//   return {
//     resource,
//     register,
//     errors,
//     isValid,
//     // rules,

//     handleSubmit,
//     saveResource: async () => {
//       const data = {
//         ...values,
//       };
//       await saveResource(data);
//     },
//     deleteResource,
//   };
// }


