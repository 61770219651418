import { useMemo } from "react"
import { taskUnits } from "../../hooks/tasks"
import { Tag } from "antd"


export default function TaskUnitLabel({ value, lowercase }) {
  let { label, color } = useMemo(() => {
    return taskUnits.find(s => s.value === value) || { label: value }
  }, [value])
  if (lowercase) {
    label = (label || '').toLowerCase()
  }
  return label
  // return <span className={`badge bg-${status.color || 'secondary'}`}>{status.label}</span>
}
