import { Button, DatePicker, Flex, Form, Input, Modal, Popconfirm, Popover, Progress, Segmented, Space, Table, Tag } from "antd";
import { formatDate, formatDateTime } from "../../lib/format";
import { useProjectsLoader } from "../../hooks/projects";
import { useCallback, useMemo, useState } from "react";
import { indexBy } from "../../lib/utils";
import { usePickingPoolOrders, useSelectedPickingPool, useSelectedPickingPoolID } from "../../hooks/pickingpools";
import Link from "../../components/link/Link";
import { usePickingOrders } from "../../hooks/pickingorders";
import { WarningOutlined } from "@ant-design/icons";
import { ResourceForPrestationInput } from "../../components/resources/ResourceForPrestationInput";
import { loaderWrap } from "../../services/loader";
import { removePickingOrder, updatePickingOrder } from "../../services/api/pickingorders";
import dayjs from "dayjs";
import { useResources } from "../../hooks/resources";
import { PrestationInput } from "../../components/prestations/PrestationInput";
import { useDeliveries } from "../../hooks/deliveries";
import { removeDelivery, updateDelivery } from "../../services/api/deliveries";

import DeliveryStatus from "../../components/deliveries/DeliveryStatus";
import { usePrestation, usePrestations } from "../../hooks/prestations";
import Icon from "@mdi/react";
import { mdiTruckRemoveOutline } from "@mdi/js";
import { invalidateShipment } from "../../services/api/shipments";
import { invalidateSlotCache } from "../../services/api/slots";
import { invalidateTaskCache } from "../../services/api/tasks";
import { useExtendedPersistentSearcher, useSearchOptionsMemo, useSearcher } from "../../hooks/search";
import Search from "../../components/search/Search";
import SearchButton from "../../components/search/SearchButton";
import { ID } from "../../components/id/id";


export default function DeliveryDeliveriesList() {
  // const [pickingPoolId] = useSelectedPickingPoolID();
  const [pickingPool] = useSelectedPickingPool();
  // const [pickingOrders, reloadPickingOrders] = usePickingOrders();

  const [deliveries, reloadDeliveries] = useDeliveries({ warehouseId: pickingPool?.warehouse_id, onlyActive: true });

  const { projects, getProject } = useProjectsLoader();
  const [resources] = useResources();
  const [prestations] = usePrestations();
  // const projectsById = useMemo(() => indexBy(projects, 'id'), [projects]);
  const resourcesIdx = useMemo(() => indexBy(resources, 'id'), [resources]);
  const prestationsIdx = useMemo(() => indexBy(prestations, 'id'), [prestations]);

  const orders = useMemo(() => {
    const orders = []
    const ordersIds = new Set();
    deliveries.forEach(d => {
      if (ordersIds.has(d.order_id)) return;
      ordersIds.add(d.order_id);
      orders.push({
        id: d.order_id,
        name: `Commande n°${d.order.order_number}`
      })
    })
    return orders;
  }, [deliveries]);

  const usedResources = useSearchOptionsMemo(resources, (r) => {
    return deliveries.some(d => d.resource_id === r.id) ? r : null;
  }, [deliveries]);


  const usedProjects = useMemo(() => {
    if (!projects) return [];
    return projects.filter(p => deliveries.some(d => d.order.project_id === p.id));
  }, [projects, deliveries]);

  const usedPrestations = useMemo(() => {
    if (!prestations) return [];
    return prestations.filter(p => deliveries.some(d => d.prestation_id === p.id));
  }, [prestations, deliveries]);

  const { list, search, setSearch, addSearch } = useExtendedPersistentSearcher(
    "shipments.deliveries",
    deliveries,
    useCallback(({ resource_id, prestation_id, order_id, order, tracking_number }, { orders, projects, prestations, resources }, fulltextSearch) => {
      if (orders && orders.length && !orders.includes(order_id)) return false;
      if (projects && projects.length && !projects.includes(order.project_id)) return false;
      if (resources && resources.length && !resources.includes(resource_id)) return false;
      if (prestations && prestations.length && !prestations.includes(prestation_id)) return false;

      return fulltextSearch(`${order.order_number} ${getProject[order.project_id]?.name} ${order.salesman_code} ${tracking_number}`)
    }, [getProject]),
  );

  const onDelete = async (id) => {
    await loaderWrap(removeDelivery(id));
    await reloadDeliveries();
  }

  const onUpdate = async (record, modifiers) => {
    await loaderWrap(updateDelivery({
      ...record,
      ...modifiers
    }));
    await reloadDeliveries();
    invalidateShipment();
    invalidateSlotCache();
    invalidateTaskCache();
  }


  return (
    <div className="box-paddingless">
      <Flex gap={"small"} style={{ padding: 10 }}>
        <Search
          name="ShipmentDeliveriesList"
          projects={usedProjects}
          resources={usedResources}
          prestations={usedPrestations}
          orders={orders}
          values={search} onFiltersChange={setSearch} />
      </Flex>

      <Table
        // bordered
        dataSource={list}
        rowKey="id"
        size='middle'
        pagination={
          {
            position: ['bottomCenter'],
          }
        }
        // onRow={(record, rowIndex) => {
        //   return {
        //     onClick: () => openSetResource(record.id), // click row
        //   };
        // }}
        columns={[
          {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 100,
            render: (id) => <ID value={id} />
          },
          {
            title: 'Commande',
            dataIndex: ['order', 'order_number'],
            key: 'order_number',
            width: 80,
            render: (order_number, o) =>
              <SearchButton type="orders" value={o.order_id} handler={addSearch} >
                <Link to={`/orders/${order_number}`}>{order_number}</Link>
              </SearchButton>
          },
          {
            title: 'Affaire',
            dataIndex: ['order', 'project_id'],
            key: 'project_id',
            render: (project_id) => {
              const project = getProject(project_id);
              if (!project) return null;
              return <SearchButton type="projects" value={project_id} handler={addSearch} >
                {project.name}
              </SearchButton>
            }
          },
          // {
          //   title: 'Date de livraison souhaitée',
          //   dataIndex: ['order', 'shipping_date'],
          //   key: 'shipping_date',
          //   width: 120,
          //   render: (date) => formatDate(date)
          // },

          {
            title: 'Date de livraison',
            dataIndex: 'delivery_date',
            key: 'delivery_date',
            width: 120,
            render: (date) => formatDateTime(date),
            sortDirections: ['descend', 'ascend'],
            defaultSortOrder: 'ascend',
            sorter: (a, b) => new Date(a.delivery_date) - new Date(b.delivery_date),
          },
          {
            title: 'Expédié le',
            dataIndex: 'shipping_date',
            key: 'shipping_date',
            width: 120,
            render: (date) => formatDateTime(date)
          },
          {
            title: 'Livré par',
            dataIndex: 'resource_id',
            key: 'resource_id',
            width: 120,
            render: (resource_id, rec) => {
              return <>
                <div>
                  <SearchButton type="resources" value={resource_id} handler={addSearch} >
                    {resourcesIdx[resource_id]?.name}
                  </SearchButton></div>
                <div style={{
                  fontSize: '0.8em',
                  fontStyle: 'italic',
                }}>
                  <SearchButton type="prestations" value={rec.prestation_id} handler={addSearch} >
                    {prestationsIdx[rec.prestation_id]?.name}
                  </SearchButton>
                </div>
              </>
            }
          },
          {
            title: 'Numéro de suivi',
            dataIndex: 'tracking_number',
            key: 'tracking_number',
          },
          // {
          //   title: 'Début de préparation',
          //   dataIndex: 'started_at',
          //   key: 'started_at',
          //   render: (date) => formatDateTime(date)
          // },
          {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status) => <DeliveryStatus value={status} />
          },



          {
            key: 'actions',
            align: "right",
            width: 100,
            render: (record) => {
              return <Space>
                {!record.confirmed ? <Button type="primary" onClick={() => onUpdate(record, {
                  confirmed: true,
                })}>Confimer le rdv</Button> : null}
                {!record.shipping_date ? <Button type="primary" onClick={() => onUpdate(record, {
                  shipping_date: new Date(),
                })}>Marquer "expédié"</Button> : null}
                {!record.delivered_date && record.shipping_date ? <Button type="primary" onClick={() => onUpdate(record, {
                  delivered_date: new Date(),
                })}>Marquer "livré"</Button> : null}
                <Popconfirm title="Êtes-vous sûr de vouloir annuler cette livraison ?"
                  description={<div>
                    Cette action: <ul>
                      <li>annulera la planification de la livraison </li>
                    </ul>
                  </div>}
                  okButtonProps={{ danger: true }}
                  okText="Oui, supprimer" onConfirm={() => onDelete(record.id)}>
                  <Button type="primary" danger>
                    <Icon path={mdiTruckRemoveOutline} size={0.8} />
                  </Button>
                </Popconfirm>
              </Space>
            }
          }

        ]}
      />


    </div>
  );
}