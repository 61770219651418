
import clsx from 'clsx';
import React from 'react';
import { NavLink, Outlet, useParams } from 'react-router-dom';


function navLinkActive(baseCls, activeClass) {
  return ({ isActive }) =>
    clsx(baseCls, isActive && activeClass)

}


export default function PrestationItem() {
  const { prestationId } = useParams();
  return (<>
    {prestationId && prestationId !== '*' ? (
      <ul className="nav nav-pills subnav mb-4">
        <li className="nav-item">
          <NavLink to="details" className={navLinkActive("nav-link", "active")}>Détail</NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="resources" className={navLinkActive("nav-link", "active")}>Resources</NavLink>
        </li>
      </ul>) : null}
    <Outlet />
  </>);
}
