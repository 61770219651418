import { duration, invalid } from 'moment';
import { cache } from '../cache';
import { withApi } from './api';
import { invalidateTaskCache } from './tasks';
import { invalidateProjectCache } from './projects';
import dayjs from 'dayjs';

const api = withApi('chronos', '/slots');
const { invalidateCache, withCache } = cache();


function mapFromSlotModel(input) {
  return {
    ...input,
    duration: +input.duration || undefined,
    start: new Date(input.start).toISOString(),
    end: new Date(input.end).toISOString(),
  };
}

function mapToSlotModel(input) {
  return {
    ...input,
    type: input.type || 'time',
    start: new Date(input.start),
    end: new Date(input.end),
  };
}

export async function listSlots({ task_id, resource_id, project_id, agency_id, dates }) {
  if (dates && dates instanceof Array) {
    dates = dates.map(d => new dayjs(d).format('YYYY-MM-DD')).join(',');
  }

  const params = Object.entries({
    task_id: task_id || undefined,
    resource_id: resource_id || undefined,
    project_id: project_id || undefined,
    agency_id: agency_id || undefined,
    dates: dates || undefined,
  }).reduce((acc, [key, value]) => {
    if (value !== undefined) {
      acc[key] = value;
    }
    return acc;
  }, {});



  const items = await withCache(async () => {
    const { items } = await api.get('', {}, params);
    return items;
  }, { list: true, ...params })
  return items ? items.map(mapToSlotModel) : [];
}

export async function getNonWorkingDays(year) {
  const items = await withCache(async () => {
    const { items } = await api.get('nonWorkingDays/' + year, {}, {});
    return items;
  }, { list: true, year })
  return items ? items : {};
}

export async function getSlot(id) {
  const item = await withCache(async () => {
    const { item } = await api.get(id);
    if (!item) {
      throw new Error('Slot not found');
    }
    return item;
  }, id)
  const slot = mapToSlotModel(item);
  return slot;
}

export async function getSlotRaw(id) {
  const { item, documents, signature } = await api.get(id);
  return { item, documents, signature };
}

export async function createSlot(slot) {
  const { item } = await api.post('', { item: mapFromSlotModel(slot) });
  if (item) {
    invalidateCache(item.id)
    invalidateCache({ list: true })
    invalidateTaskCache(item.task_id)
    invalidateProjectCache(item.project_id)
  }
  return item ? mapToSlotModel(item) : undefined;
}

export async function updateSlot(slot) {
  const { item } = await api.put('', { item: mapFromSlotModel(slot) });
  invalidateCache(slot.id)
  invalidateCache({ list: true })
  invalidateTaskCache(slot.task_id)
  invalidateProjectCache(slot.project_id)
  return item ? mapToSlotModel(item) : undefined;
}

export async function removeSlot(id) {
  const res = await api.delete(id);
  invalidateCache(id)
  invalidateCache({ list: true })
  return !!res;
}

export function invalidateSlotCache(slotId) {
  invalidateCache(slotId)
}
