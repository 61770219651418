import { useNavigate, useParams } from "react-router-dom";
import { usePickingPool, usePickingPoolOrders, useSelectedPickingPoolID } from "../../hooks/pickingpools";
import { Button, ConfigProvider, Flex, Form, Input, InputNumber, Menu, Modal, Popconfirm, Popover, Progress, Segmented, Select, Space, Table, Tabs, Tag, Tooltip, message } from "antd";
import { createContext, useCallback, useEffect, useMemo, useState } from "react";
import { formatDate, formatDateTime } from "../../lib/format";
import { useResources } from "../../hooks/resources";
import { usePrestations } from "../../hooks/prestations";
import { useProjectsLoader } from "../../hooks/projects";
import { indexBy } from "../../lib/utils";
import { createPickingOrder, removePickingOrder, updatePickingOrder } from "../../services/api/pickingorders";
import { pickingPriorities, usePickingOrders } from "../../hooks/pickingorders";
import PickingStatus from "../../components/picking/PickingStatus";
import TableEditableRow, { TableEditableRowFactory } from "../../components/table/TableEditableRow";
import TableEditableCell, { TableEditableCellFactory } from "../../components/table/TableEditableCell";
import { CheckCircleOutlined, Loading3QuartersOutlined, ReloadOutlined, StopOutlined } from "@ant-design/icons";
import { useExtendedPersistentSearcher, useSearcher, useSearchOptionsMemo } from "../../hooks/search";
import { loaderWrap } from "../../services/loader";
import Link from "../../components/link/Link";
import Icon from "@mdi/react";
import ResourceIcon from "../../components/resources/ResourceIcon";
import { invalidatePickingPool } from "../../services/api/pickingpools";
import ShipmentStatus from "../../components/shipments/ShipmentStatus";
import { useShipments } from "../../hooks/shipments";
import { createShipment, invalidateShipment } from "../../services/api/shipments";
import Avatar from "../../components/avatar/Avatar";
import { mdiAccountPlus, mdiPackageVariantClosedCheck, mdiPackageVariantPlus, mdiPackageVariantRemove, mdiPlayBoxOutline, mdiRefresh } from "@mdi/js";
import ShipmentMissingProducts from "../../components/shipments/ShipmentMissingProducts";
import PickingPriority from "../../components/picking/PickingPriority";
import { adjustCapacity } from "../../lib/capacity";
import ShipmentAddPickingModal from "./ShipmentAddPickingModal";
import { green, yellow, orange, red } from '@ant-design/colors';
import OrderShippingModeLabel from "../../components/orders/OrderShippingModeLabel";
import Search from "../../components/search/Search";


function SegmentResource({ label, resource, capacity, maxCapacity, selected }) {
  if (!resource) {
    return <Flex align="center" style={{ height: 60 }}>
      <div>{label}</div>
    </Flex>
  }
  return <Flex vertical gap={0} style={{ padding: 10 }}>
    <Space align="center" >
      <div >
        <Avatar username={resource.name} size={24} />
      </div>
      <div style={{ fontWeight: 500 }} >{resource.name}</div>
    </Space>
    <div style={{ fontSize: '0.7em', lineHeight: '0.8em', opacity: 0.8 }}>{capacity}{maxCapacity > 0 && <> / {Math.ceil(maxCapacity)}</>}</div>
    {maxCapacity > 0 && <Progress percent={Math.round(100 * capacity / maxCapacity)}
      strokeColor={{
        '0%': green.primary,
        '70%': green.primary,
        '85%': yellow.primary,
        '90%': orange.primary,
        '100%': red.primary
      }}
      status="active"
      showInfo={false}
    // percentPosition={{
    //   align: 'end',
    //   type: 'inner',
    // }}
    // size={[100, 10]}
    // format={(v) => <span style={{
    //   fontSize: '0.5em',
    //   paddingRight: 5,
    // }}>{v}%</span>}
    // status={capacity > maxCapacity ? 'exception' : 'normal'}
    // status="normal"
    />}
  </Flex>
}


export default function ShippingPicking() {
  const [pickingPoolId] = useSelectedPickingPoolID();
  const { pickingPool,
    orders: incomingOrders,
    warehouseId,
    // removeOrder: removeOrderFromPool,
    reloadPickingPool
  } = usePickingPoolOrders(pickingPoolId);
  // const [pickingOrders, reloadPickingOrders, pickingOrderComplete] = usePickingOrders();
  const [displayMode, setDisplayMode] = useState('');

  const [shipments, reloadShipments, shipmentComplete] = useShipments({ warehouseId, onlyActive: true });


  const [prestations] = usePrestations();

  const pickingOrders = useMemo(() => {
    const out = [];
    shipments.forEach(s => {
      if (!s.picking_orders) return;
      out.push(...s.picking_orders.map(p => (
        {
          ...p,
          shipment: s,
          order: s.order,
        }
      )));
    });
    return out;
  }, [shipments]);



  const warehouseCode = warehouseId?.split('-')[1]
  const warehouseCapacity = pickingPool?.capacities?.[warehouseId] || 0;
  const prestationId = prestations.find(p => p.code === 'CMDPREP' + warehouseCode)?.id;
  const [resources, , resourcesComplete] = useResources({ prestationId });
  const { projects, getProject } = useProjectsLoader();


  const [selectedAffectOrder, setSelectedAffectOrder] = useState(null);
  const [selectedFinishOrder, setSelectedFinishOrder] = useState(null);

  const isComplete = prestationId && resourcesComplete;

  const reload = () => {
    reloadShipments();
    invalidatePickingPool(pickingPoolId);
    reloadPickingPool();
  }

  const loading = !isComplete || !shipmentComplete;


  const shipmentsOrdersIndex = useMemo(() => indexBy(shipments, "order_id"), [shipments])





  // const onCreateShipment = async (order) => {
  //   const ship = await loaderWrap(createShipment({
  //     order_id: order.id,
  //     status: 'pending',
  //     warehouse_id: warehouseId,
  //   }))
  //   reload();
  //   message.success('Expédition créée');
  //   openSetResource({
  //     ...order,
  //     shipment_id: ship.id,
  //     shipment: ship,
  //   })
  // }

  const addResource = async (order) => {
    if (!shipmentsOrdersIndex[order.id]) {
      alert('No shipment found')
      return
    }
    openSetResource({ ...order, shipment_id: shipmentsOrdersIndex[order.id]?.id })
  }
  const openSetResource = (order) => {
    // setModalSetResourceOpen(true);
    setSelectedAffectOrder(order);
  }


  // Start
  const onOrderPickingStart = async (item) => {
    await updatePickingOrder({
      ...item,
      status: 'started',
    })
      .then(reload);
  }
  // Finish
  const [formFinish] = Form.useForm();
  const [finishLoading, setFinishLoading] = useState(false);
  const [modalFinishOpen, setModalFinishOpen] = useState(false);
  const [selectedOrderLines, setSelectedOrderLines] = useState([]);
  const { list: filteredSelectedOrderLines, search: orderLinesSearch, setSearchOrderLines } = useSearcher(selectedOrderLines,
    (item, search, san) => san(`${item.line_number} ${item.item_code} ${item.label}`).includes(search))
  const onOrderPickingFinish = (item) => {
    setModalFinishOpen(true);
    setSelectedFinishOrder(item);

    const existings = pickingOrders.filter(p => p.order_id === item.order_id && item.id !== p.id)
      .reduce((acc, p) => {
        p.items?.forEach((i) => {
          const existing = acc.find(e => e.line_number === i.line_number);
          if (existing) {
            existing.picked_quantity += i.picked_quantity;
          } else {
            acc.push({ ...i });
          }
        });
        return acc;
      }, []);

    const existingIdx = indexBy(existings, 'line_number');


    let lines = item.order?.items
      .filter(({ item_type }) => item_type === 'MAR' || item_type === 'NOM')
      .filter(({ line_number }) => !existingIdx[line_number] || existingIdx[line_number].picked_quantity < existingIdx[line_number].expected_quantity)
      .map((item) => ({
        ...item,
        picked_quantity: item.remain_quantity - (existingIdx[item.line_number]?.picked_quantity || 0),
      }))




    setSelectedOrderLines(lines);
  }
  const onSetPickedQty = (row, value) => {
    setSelectedOrderLines(selectedOrderLines
      .map((r) => r.line_number === row.line_number
        ? { ...row, picked_quantity: value }
        : r));
  };

  const onModalFinishOk = () => {
    setFinishLoading(true);
    formFinish.validateFields()
      .then(async ({ resource_id }) => {
        await loaderWrap(updatePickingOrder({
          ...selectedFinishOrder,
          status: 'finished',
          items: selectedOrderLines.map((l) => {
            return {
              line_number: l.line_number,
              picked_quantity: l.picked_quantity,
              expected_quantity: l.remain_quantity
            }
          })
        }))
        reload();
        setModalFinishOpen(false);
      })
      .finally(() => {
        setFinishLoading(false);
      });
  }

  const onDelete = async (item) => {
    await loaderWrap(removePickingOrder(item.id))
      .then(reload);

  }

  // const onDeleteOrderFromPool = async (orderId) => {
  //   removeOrderFromPool(orderId, true)
  // }

  const capacitiesByResource = useMemo(() => {
    const capacities = {};
    // const incomingOrdersIdx = indexBy(incomingOrders, 'id');
    pickingOrders.forEach(({ status, resource_id, order_id, order }) => {
      if (status === 'finished') return;
      const { remaining_items_count } = order || { remaining_items_count: 1 };
      if (!remaining_items_count) return
      capacities[resource_id] = (capacities[resource_id] || 0) + remaining_items_count;
    });
    return capacities;
  }, [pickingOrders]);


  const resourceId = displayMode.startsWith('res:') ? displayMode.replace('res:', '') : null;

  const orders = useMemo(() => {
    if (!resourceId) {
      const pickedOrdersIndex = indexBy(pickingOrders, 'order_id');
      return incomingOrders
        .filter(o => !pickedOrdersIndex[o.id])
        .map(o => ({
          ...o,
          shipment: shipmentsOrdersIndex[o.id],
        }));
    }
  }, [incomingOrders, pickingOrders, resourceId, shipmentsOrdersIndex]);


  const activeResourceMap = useMemo(() => indexBy(pickingOrders.filter(p => p.status !== 'finished'), 'resource_id'), [pickingOrders]);
  const activeResources = useMemo(() => resources.filter(r => activeResourceMap[r.id]), [resources, activeResourceMap]);



  const { list: pickings, search, setSearch, addSearch } = useExtendedPersistentSearcher(
    "ShipmentsPickings",
    useMemo(() => {
      return pickingOrders
        .filter(p => (!resourceId || p.resource_id === resourceId) && p.status !== 'finished')
        .map(p => ({
          ...p.order,
          ...p,
        }));
    }, [pickingOrders, resourceId]),
    useCallback(({ order }, { orders, thirdparties, projects }, fulltextSearch) => {
      if (orders && orders.length && !orders.includes(order.id)) return false;
      // if (thirdparties && thirdparties.length && !thirdparties.includes(order.third_party_code || order.customer_code)) return false;
      if (projects && projects.length && !projects.includes(order.project_id)) return false;
      return fulltextSearch(`${order.order_number} ${getProject(order.project_id)?.name} ${order.salesman_code}`)
    }, [getProject]),
  );



  useEffect(() => {
    if (displayMode) return;
    if (!activeResources?.length) return;
    console.log('set display mode', activeResources[0].id)
    setDisplayMode('res:' + activeResources[0].id);
  }, [activeResources, displayMode]);


  const maxCapacity = adjustCapacity(warehouseCapacity / activeResources.length, 'day');

  const projectsFilter = useSearchOptionsMemo(pickings, (s) => {
    const id = s.order?.project_id;
    return {
      id, name: getProject(id)?.name || 'Affaire n°' + id
    }
  }, [getProject]);

  const ordersFilters = useSearchOptionsMemo(orders, (o) => {
    return {
      id: o.id,
      name: o.order_number
    }
  }, [orders]);

  if (!isComplete) return null


  return (
    <Flex vertical gap="large">

      <div className="box-paddingless">
        <Flex gap="small" align='center' style={{ padding: 10 }} >
          <Search
            name="PickingList"
            orders={ordersFilters}
            // thirdparties={thirdparties}
            projects={projectsFilter}
            values={search} onFiltersChange={setSearch}
          />
          <div>
            <Button title="Rafraichir" onClick={reload} shape="rounded" loading={loading} icon={<ReloadOutlined />} />
          </div>
        </Flex>
        <Menu
          style={{ flex: 1 }}
          mode="horizontal"
          selectedKeys={[displayMode]}
          onClick={({ key }) => setDisplayMode(key)}
          items={
            [
              ...activeResources
                .map(r => ({
                  label: <SegmentResource
                    resource={r}
                    selected={displayMode === 'res:' + r.id}
                    capacity={capacitiesByResource[r.id]}
                    maxCapacity={maxCapacity} />,
                  key: 'res:' + r.id
                }))
            ]
          }
        />
        <Table
          key="table-pickings"
          // bordered
          dataSource={pickings}
          rowKey="id"
          size='middle'
          pagination={
            {
              position: ['bottomCenter'],
            }
          }
          // onRow={(record, rowIndex) => {
          //   return {
          //     onClick: () => openSetResource(record.id), // click row
          //   };
          // }}
          columns={[
            {
              title: 'Numéro',
              dataIndex: 'order_number',
              key: 'order_number',
              render: (order_number, o) => <Link to={`/orders/${o.order_id}`}>{order_number}</Link>
            },
            {
              title: 'Affaire',
              dataIndex: 'project_id',
              key: 'project_id',
              render: (project_id) => {
                const project = getProject(project_id);
                return project && project.name;
              }
            },
            {
              title: 'Date de livraison',
              dataIndex: 'shipping_date',
              key: 'shipping_date',
              render: (date) => formatDate(date),
              // defaultSortOrder: "ascend",
              sorter: (a, b) => new Date(a.shipping_date) - new Date(b.shipping_date),
            },
            {
              title: 'Mode d\'expédition',
              dataIndex: 'shipping_mode',
              key: 'shipping_mode',
              render: (mode) => <OrderShippingModeLabel value={mode} />
            },
            {
              title: 'Priorité',
              dataIndex: 'priority',
              key: 'priority',
              width: 80,
              render: (priority) => <PickingPriority value={priority} />,
              sortDirections: ['descend', 'ascend'],
              sorter: (a, b) => a.priority - b.priority,
              defaultSortOrder: 'descend',
            },
            {
              title: 'Début de préparation',
              dataIndex: 'started_at',
              key: 'started_at',
              render: (date) => formatDateTime(date)
            },
            {
              title: 'Status',
              dataIndex: 'status',
              key: 'status',
              render: (status) => <PickingStatus value={status} />
            },
            // {
            //   title: 'État',
            //   dataIndex: 'completion_rate',
            //   width: 100,
            //   key: 'completion_rate',
            //   render: (completion_rate, item) => {
            //     if (completion_rate === 1) {
            //       return <Tag color="green">Complète</Tag>
            //     }
            //     // return <Progress
            //     //   percent={Math.floor(100 * completion_rate)}
            //     //   strokeColor={completion_rate < 1 ? 'orange' : null}
            //     //   status="normal" />
            //     return <Popover content={<ShipmentMissingProducts items={item.items} order={item.order} />}>
            //       <Progress
            //         percent={Math.floor(100 * completion_rate)}
            //         strokeColor={completion_rate < 1 ? 'orange' : null}
            //         status="normal" />
            //     </Popover>
            //   }
            // },
            // {
            //   title: 'Statut',
            //   dataIndex: 'status',
            //   key: 'status',
            //   render: (status) => <Tag color={status === 'draft' ? 'orange' : 'green'}>{status}</Tag>
            // },
            {
              title: 'Nb réfs',
              key: 'items_count',
              dataIndex: 'remaining_items_count',
              // render: (record) => <a href={`/orders/${record.id}`}>Voir</a>
            },
            {
              title: '',
              key: 'actions',
              align: "right",
              render: (record) => {
                return <Space>
                  {record.status === 'pending' &&
                    <Tooltip title="Commencer la préparation">
                      <Button type="primary" onClick={() => onOrderPickingStart(record)}><Icon path={mdiPlayBoxOutline} size={0.8} /></Button>
                    </Tooltip>
                  }
                  {record.status === 'started' &&
                    <ConfigProvider
                      theme={{
                        components: {
                          Button: {
                            colorPrimary: '#2bb673',
                            colorPrimaryHover: '#30dd8a',
                          }
                        }
                      }}
                    >
                      <Tooltip title="Valider la préparation">
                        <Button type="primary" color="green" onClick={() => onOrderPickingFinish(record)}>
                          <Icon path={mdiPackageVariantClosedCheck} size={0.8} />
                        </Button>
                      </Tooltip>
                    </ConfigProvider>
                  }
                  {(record.status === 'pending' || record.status === 'finished') && record.completion_rate < 1 &&
                    <Tooltip title="Ajouter un préparateur">
                      <Button><Icon path={mdiAccountPlus} size={0.8} onClick={() => addResource(record.order)} /></Button>
                    </Tooltip>
                  }
                  <Popconfirm title="Êtes-vous sûr de vouloir annuler la préparation ?"
                    okButtonProps={{ danger: true }}
                    okText="Oui, supprimer" onConfirm={() => onDelete(record)}>
                    <Button type="primary" danger>
                      <Icon path={mdiPackageVariantRemove} size={0.8} />
                    </Button>
                  </Popconfirm>
                </Space>
              }
            }
          ]}
        />
      </div>

      <ShipmentAddPickingModal
        warehouseId={warehouseId}
        order={selectedAffectOrder}
        onFinished={() => {
          setSelectedAffectOrder(null);
          reload();
        }} />



      <Modal
        title="Valider la préparation"
        destroyOnClose={true}
        maskClosable={false}
        centered
        open={modalFinishOpen}
        onOk={onModalFinishOk}
        confirmLoading={finishLoading}
        cancelText="Annuler"
        width={800}
        onCancel={() => {
          setModalFinishOpen(null)
          formFinish.resetFields();
        }}
      >
        <Flex vertical gap={"small"}>
          <Input.Search placeholder="Rechercher..." value={orderLinesSearch}
            onChange={e => setSearchOrderLines(e.target.value)} />
          <Table
            dataSource={filteredSelectedOrderLines}
            rowKey="line_number"
            size='small'
            pagination={
              {
                position: ['bottomCenter'],
              }
            }
            components={{
              body: {
                // row: (...props) => <TableEditableRow {...props} editableContext={LinesEditableContext} />,
                // cell: (...props) => <TableEditableCell {...props} editableContext={LinesEditableContext} />,
                row: TableEditableRow,
                cell: TableEditableCell,
              },
            }}
            columns={[
              {
                title: 'Ligne',
                dataIndex: 'line_number',
                key: 'line_number',
                filterSearch: true,
                onFilter: (value, record) => record.line_number.includes(value),
              },
              {
                title: 'Référence',
                dataIndex: 'item_code',
                key: 'item_code',
              },
              {
                title: 'Nom',
                dataIndex: 'label',
                key: 'label',
              },
              {
                title: 'Quantité',
                dataIndex: 'remain_quantity',
                key: 'remain_quantity',
              },
              {
                title: 'Préparé',
                dataIndex: 'picked_quantity',
                key: 'picked_quantity',
                render: (picked_quantity, record) => {
                  let color = 'green'
                  let icon = <CheckCircleOutlined style={{ color }} />
                  let changed = false
                  if (picked_quantity < record.remain_quantity) {
                    color = 'orange'
                    icon = <Loading3QuartersOutlined style={{ color }} />
                    changed = true
                  }
                  if (+picked_quantity === 0) {
                    color = 'red'
                    icon = <StopOutlined style={{ color }} />
                  }
                  return <ConfigProvider
                    theme={{
                      components: {
                        InputNumber: {
                          colorText: color,
                        }
                      }
                    }}
                  ><InputNumber
                      suffix={icon}
                      style={{ color, fontWeight: changed ? 'bold' : 'normal' }}
                      value={picked_quantity}
                      max={record.remain_quantity}
                      min={0}
                      step={1}
                      onChange={value => onSetPickedQty(record, value)}
                    /></ConfigProvider>
                }
              }
            ]} />
        </Flex>
      </Modal>

    </Flex>
  );
}