
import { useNavigate, useParams } from 'react-router-dom';
import DeleteButton from '../../components/deletebutton/DeleteButton';
import { resourceRoles, resourceTypes, useResource } from '../../hooks/resources';
import { ControlBar } from '../../components/controlbar/ControlBar';

import { Flex, Button, Form, Input, InputNumber, Select, Space, Switch, message, Checkbox, Divider, Tag } from 'antd';
import { useEffect, useState } from 'react';
import { AgencyInput } from '../../components/agencies/AgencyInput';

import awsconfig from '../../amplifyconfiguration';
import { PaperClipOutlined } from '@ant-design/icons';
import ResourceMessagesView from '../../components/resources/ResourceMessagesView';
import PickingPoolInput from '../../components/pickingpools/PickingPoolInput';
import Avatar from '../../components/avatar/Avatar';
import ResourceSubscriptionsForm from '../../components/resources/ResourceSubscriptionsForm';

function genAvatarSeed() {
  return Math.random().toString(32).replace(/^0\./, '')
}

export default function ResourceForm() {
  const navigate = useNavigate();
  const { resourceId } = useParams();
  const [form] = Form.useForm();
  const [messageApi, messageContextHolder] = message.useMessage();
  const [avatarSeed, setAvatarSeed] = useState(genAvatarSeed());


  const userAccessEnabled = Form.useWatch(["user", "enabled"], { form });
  const calendarEnabled = Form.useWatch(["calendar_feeds", "enabled"], { form });
  const subscriptionEnabled = Form.useWatch(["subscription", "enabled"], { form });
  const email = Form.useWatch(["user", "email"], { form });
  // const avatarSeed = Form.useWatch(["avatar_seed"], { form });
  const name = Form.useWatch(["name"], { form });

  const {
    resource,
    saveResource, deleteResource,
  } = useResource(resourceId || '');


  useEffect(() => {
    if (resource) {
      form.setFieldsValue({
        ...resource,
        // avatar_seed: resource.avatar_seed || resource.name || genAvatarSeed(),
      });
      setAvatarSeed(resource.avatar_seed || resource.name || genAvatarSeed())
    }
  }, [form, resource]);


  useEffect(() => {
    if (resource && userAccessEnabled) {
      resource.user = {};
      const name = form.getFieldValue("name") || resource.name || '';
      const agency_id = form.getFieldValue("agency_id") || resource.agency_id;
      const commercial_code = form.getFieldValue("commercial_code") || resource.commercial_code;
      const given_name = form.getFieldValue(["user", "given_name"]) || name.split(/\s+/)[0];
      const family_name = form.getFieldValue(["user", "family_name"]) || name.split(/\s+/)[1];
      form.setFieldValue(["user", "given_name"], given_name);
      form.setFieldValue(["user", "family_name"], family_name);
      form.setFieldValue(["user", "default_agency_id"], agency_id);
    }
  }, [form, name, resource, userAccessEnabled]);

  if (!resource) {
    return <div>Not found</div>;
  }

  const onFinish = (values) => {
    (async () => {
      const { id } = await saveResource({
        ...resource,
        ...values,
        avatar_seed: avatarSeed,
      });

      messageApi.success('Enregistré');
      navigate('/resources/' + id);
    })().catch((err) => {
      console.log(err)
      messageApi.error(`Erreur: ${err}`);
    });
  };

  const onDelete = () => {
    if (resourceId) {
      (async () => {
        await deleteResource();
        messageApi.success('Supprimé');
        setTimeout(() => {
          navigate('/resources');
        }, 1000);
      })();
    }
  };

  const onUpdateAvatarSeed = () => {
    setAvatarSeed(genAvatarSeed());
  }

  const calendarUrl = resource.calendar_feeds?.access_key
    ? `${awsconfig.API.REST.chronos.endpoint}/calendar/${resource.calendar_feeds.access_key}`
    : null;

  const onCopyCalendarURL = () => {
    navigator.clipboard.writeText(calendarUrl)
      .then(() => {
        message.success('URL copiée dans le presse papier');
      })
  }

  return (<>
    <Flex gap="large">
      <Form
        style={{ flex: 1 }}
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ flex: 1 }}
        labelWrap
        onFinish={onFinish}
      >
        {messageContextHolder}
        < fieldset className="box" >
          <legend>Informations</legend>

          {/* <div className="form-check form-switch form-check-reverse">
          <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"
            {...register("enabled", { className: "form-check-input" })} />
        </div> */}
          <Form.Item name="enabled" label="Actif">
            <Switch />
          </Form.Item>

          {/* <Form.Item label="Avatar">
            <Space>
              <Avatar seed={avatarSeed} size={32} />
              <Button onClick={onUpdateAvatarSeed}>Changer</Button>
            </Space>
          </Form.Item> */}

          <Form.Item name="name" label="Nom" rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item name="code" label="Code">
            <Input />
          </Form.Item>
          <Form.Item name="commercial_code" label="Code Commercial">
            <Input />
          </Form.Item>
          <Form.Item name={["user", "email"]} label="Email" rules={[{ required: userAccessEnabled }]}>
            <Input />
          </Form.Item>

          <Form.Item name="type" label="Type" rules={[{ required: true }]}>
            <Select options={resourceTypes} />
          </Form.Item>

          <Form.Item name="agency_id" label="Agence">
            <AgencyInput allowClear />
          </Form.Item>

          <Form.Item name="capacity" label="Capacité">
            <InputNumber suffix="jours" min={0} />
          </Form.Item>

          <Form.Item name="description" label="Notes">
            <Input.TextArea />
          </Form.Item>

          <Form.Item>
            <Form.Item noStyle name="hide_in_planning" label="" valuePropName="checked">
              <Checkbox>Ne pas afficher dans le planning</Checkbox>
            </Form.Item>
          </Form.Item>

        </fieldset >



        <fieldset className="box">
          <legend>Accès Utilisateur</legend>
          <Form.Item name={["user", "enabled"]} label="Activer">
            <Switch />
          </Form.Item>
          {userAccessEnabled ? <>
            {resource.user?.cognito_status &&
              <Form.Item label="Status">
                <Tag>{resource.user?.cognito_status}</Tag>
              </Form.Item>
            }
            <Form.Item name={["user", "given_name"]} label="Prénom" rules={[{ required: true }]} >
              <Input />
            </Form.Item>
            <Form.Item name={["user", "family_name"]} label="Nom" rules={[{ required: true }]} >
              <Input />
            </Form.Item>
            {/* <Form.Item name={["user", "email"]} label="Email" rules={[{ required: true }]}>
              <Input />
            </Form.Item> */}
            <Form.Item name={["user", "role"]} label="Role" rules={[{ required: true }]}>
              <Select options={resourceRoles} />
            </Form.Item>
            <Divider orientation="left">Restrictions</Divider>
            <Form.Item name={["user", "default_pool_id"]} label="Pool" >
              <PickingPoolInput allowClear />
            </Form.Item>
            <Form.Item name={["user", "default_agency_id"]} label="Agence">
              <AgencyInput allowClear />
            </Form.Item>
            <Form.Item name={["user", "commercial_codes"]} label="Codes commercial">
              <Select
                mode="tags"
                tokenSeparators={[',']}
              // options={options}
              />
            </Form.Item>
          </> : null}
        </fieldset>
        <fieldset className="box">
          <legend>Calendrier</legend>
          <Form.Item name={["calendar_feeds", "enabled"]} label="Activer">
            <Switch />
          </Form.Item>
          {calendarEnabled ? <>

            {calendarUrl && <Form.Item label="URL">
              <Space.Compact style={{ width: '100%' }}>
                <Input readOnly value={calendarUrl} />
                <Button type="primary" icon={<PaperClipOutlined />}
                  onClick={() => { onCopyCalendarURL() }} />
              </Space.Compact>
            </Form.Item>}
            <Form.Item label="Flux">
              <Flex vertical>
                <Form.Item noStyle >
                  <Checkbox disabled checked>Créneaux affectés</Checkbox>
                </Form.Item>
                <Form.Item noStyle valuePropName="checked" name={["calendar_feeds", "in_planning_tasks"]}>
                  <Checkbox>Voir les tâches en cours de plannification</Checkbox>
                </Form.Item>
                <Form.Item noStyle valuePropName="checked" name={["calendar_feeds", "show_all_slots"]}>
                  <Checkbox>Voir tous les créneaux liées à la tache</Checkbox>
                </Form.Item>
                <Form.Item noStyle valuePropName="checked" name={["calendar_feeds", "show_all_projects"]}>
                  <Checkbox>Voir tous les créneaux liées au projet</Checkbox>
                </Form.Item>
                <Form.Item noStyle valuePropName="checked" name={["calendar_feeds", "show_deliveries_summary"]}>
                  <Checkbox>Afficher un condensé les livraisons du jour</Checkbox>
                </Form.Item>
              </Flex>
            </Form.Item>
          </> : null}


        </fieldset>

        {email !== "" && <fieldset className="box">
          <legend>Notifications</legend>
          <Form.Item name={["subscription", "enabled"]} label="Activer">
            <Switch />
          </Form.Item>
          {subscriptionEnabled && <ResourceSubscriptionsForm />}
        </fieldset>}


      </Form >
      {resource.id ?
        <fieldset className='' style={{ maxWidth: 300 }}>
          <ResourceMessagesView resourceId={resource.id} />
        </fieldset> : null}
    </Flex>
    <ControlBar>
      <DeleteButton onDelete={onDelete} />
      <div>
        <Button type="primary" size='large'
          onClick={() => form.submit()}>Enregistrer</Button>
      </div>
    </ControlBar>
  </>);
}
